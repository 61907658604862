@font-face{
  font-family: Ailerons;
  src: url(assets/Ailerons/Ailerons.otf);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 1000;
  /*background-image: linear-gradient(to right, #eaeaea, #f2f2f2, #f3f3f3, #f4f4f4, #f6f6f6, #f5f5f5, #f6f6f6, #f8f8f8)*/
  background-color: #212121;
  padding-bottom: 5px;
}

.navbar-content {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.navbar a {
  text-decoration: none;
  color: #eaeaea;
}

.navbar-logo {
  display: flex;
  font-family: Ailerons;
  font-size: 65px;
  color: #eaeaea;
  /*color: #606060;*/
  /*margin-left: 5%;*/
}

.play-icon {
  width: 50px;
  height: 50px;
}

.play-icon img {
  max-width: 100%;
  max-height: 100%;
}

.navbar-menu-items {
  font-size: 20px;
}

.navbar-menu-items a {
  margin-right: 45px;
}

.slideshow {
  position: relative;
}

.home-landing {
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url("./assets/slideshow.jpg");
  /*height: 100vh;*/
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-landing-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 45px;
  margin: auto;
  position: absolute;
  top: 30%;
  letter-spacing: 3px;
  font-weight: 300;
  color: #212121;
  text-transform: uppercase;
  /*line-height: 2;*/
}

.home-landing-title span {
  font-size: 35px;
  margin-top: 20px;
}

.home-landing-title a {
  margin: 50px;
  padding: 12px;
  font-size: 23px;
  font-weight: 200;
  border-radius: 7px;
  letter-spacing: 1px;
  background-color: #212121;
  color: #eaeaea;
  cursor: pointer;
  text-decoration: none;
}

.home-mid-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.home-mid-section-title {
  font-size: 36px;
  color: red;
}

.home-mid-section-subtitle {
  font-size: 20px;
  color: #919192;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 30px;
}

.home-services {
  display: flex;
  width: 1200px;
}

.iconview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.iconview-icon {
  width: 225px;
  height: 145px;
  background-position: center;
  background-repeat: no-repeat;
}

.iconview-label {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  line-height: 24px;
}

.iconview-text {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 10px;
  width: 300px;
  height: 200px;
  text-align: center;
}

.iconview-btn {
  padding: 12px 17px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 7px;
  background-color: #008bf6;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.home-mid-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  margin-top: 20px;
  background-color: #f6f6f6;
  padding-bottom: 20px;
}

.home-mid-bottom-title {
  font-size: 30px;
  color: #919192;
  font-weight: 400;
  margin-bottom: 40px;
}

.home-mid-bottom-content {
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.detail-demo {
  width: 401px;
  margin-bottom: 35px;
}

.detail-demo-label {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-weight: 500;
}

.detail-demo-text {
  font-weight: 200;
  line-height: 1.4;
  border-left: 1px solid red;
  padding-left: 10px
}

.action-to-call {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.action-to-call h1 {
  margin: auto;
  font-weight: 300;
  font-size: 45px;
  border-bottom: 1px solid red;
  padding-bottom: 8px;
  text-align: center;
  /*width: 15%;*/
}

.action-to-call a {
  margin: auto;
  font-size: 24px;
}

.action-items {
  display: flex;
  justify-content: center;
}

.action-items div {
  margin: 50px;
  width: 300px;
  text-align: center;
}

.action-items p {
  line-height: 1.4;
  color: #212121;
}

.publishers-bg {
  background-image: url('./assets/pubs-bg.jpg');
  min-height: 60vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.publishers-content-container {
  background-color: #f6f6f6;
}

.publishers-outstream {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  flex-flow: wrap-reverse;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pubs-outstream-content {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.pubs-outstream-content p {
  line-height: 1.4;
}

.pubs-outstream-image {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
}

.pubs-outstream-image div {
  width: 200px;
  height: 200px;
  display: flex;
  margin-right: 10px;
}

.pubs-outstream-image img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.publishers-instream {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  flex-flow: wrap;
  padding-top: 60px;
  padding-bottom: 60px;
}

.pubs-instream-content {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.pubs-instream-content p {
  line-height: 1.4;
}

.publishers-action {
  background-color: #f6f6f6;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.publishers-action-image {
  margin: 30px;
}

.advertisers-top {
  padding-top: 100px;
  display: flex;
  justify-content: space-around;
  background-image: url('./assets/old-tv1.jpg');
  min-height: 45vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.advertisers-top-content {
  color: white;
}

.advertisers-top-placeholder {
  width: 600px;
}

.advertisers-top-content h1 {
  padding-bottom: 5px;
  border-bottom: 1px solid red;
  width: 600px;
}

.advertisers-top-content p {
  font-size: 25px;
  max-width: 600px;
  font-weight: 500;
  line-height: 1.3
}

.advertisers-middle {
  width: 1200px;
  margin: auto;
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  align-items: center;
  padding-bottom: 60px;
}

.advertisers-middle-left {
  width: 600px;
}

.advertisers-middle-right {
  font-size: 18px;
  line-height: 1.4;
}

.advertisers-middle-title {
  font-size: 23px;
  margin-bottom: 20px;
  border-bottom: 1px solid red;
}

.footer {
  background-color: #212121;
  text-align: center;
  padding: 20px;
}

.footer a {
  text-decoration: none;
  color: #eaeaea;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-media div {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.social-media img {
  max-width: 100%;
  max-height: 100%;
}

.policies {
  margin-top: 30px;
}

.pp-tc {
  width: 1200px;
  margin: auto;
  padding: 100px;
}

.pp-tc {
  color: #333;
}

.modal-backdrop {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.contact-page {
  display: flex;
  height: 80vh;
  padding-top: 50px;
  padding-bottom: 50px;
}

.email-form {
  margin: auto;
  display: flex;
  background-color: #eaeaea;
  padding: 30px;
  flex-direction: column;
  font-size: 20px;
  font-weight: 200;
  width: 400px;
}

.email-form label {
  margin-bottom: 20px;
}

.email-form input {
  width: 100%;
  height: 25px;
}

.email-form textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.email-form button {
  font-size: 20px;
  background-color: #212121;
  border-radius: 5px;
  color: #eaeaea;
  padding: 5px;
  cursor: pointer;
}

.login-page {
  display: flex;
  position: relative;
  justify-content: center;
  background-image: url('./assets/login-bg.jpg');
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-form {
  margin: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%;
  width: 300px;
  font-size: 20px;
  color: white;
}

.login-form label {
  margin-bottom: 20px;
}

.login-form input {
  width: 100%;
  height: 25px;
}

.login-form button {
  font-size: 20px;
  background-color: #212121;
  border-radius: 5px;
  color: #eaeaea;
  padding: 5px;
  cursor: pointer;
}

.login-response-text {
  position: absolute;
  top: 52%;
}

.baseline-demo {
  width: 630px;
  padding-top: 100px;
  margin: auto;
  color: #212121;
}

.baseline-demo h2 {
  text-align: center;
}

.banner-demo {
  width: 1000px;
  padding-top: 100px;
  margin: auto;
  color: #212121;
}

.banner-demo-container {
  display: flex;
}

.banner-demo h1 {
  text-align: center;
}

.banner-demo-content {
  width: 60%;
  padding: 15px;
}

.banner-demo-sidebar {
  width: 33%;
}

.banner-demo-sidebar-top {
  height: 300px;
  width: 278px;
  padding: 10px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.banner-demo-sidebar-top a {
  margin-bottom: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.banner-demo-sidebar-sticky {
  position: fixed;
  top: 75px;
}

#sticky-player {
  width: 300px;
  height: 250px;
}

@media screen and (max-width: 600px) {
  .navbar-logo {
    font-size: 27px;
  }

  .navbar {
    height: 35px;
    padding-bottom: 0px;
  }

  .navbar-content {
    width: 100%;
  }

  .play-icon {
    width: 20px;
    height: 20px;
  }

  .navbar-menu-items {
    font-size: 10px;
  }

  .navbar-menu-items a {
    margin-right: 10px;
  }

  .home-landing-title {
    font-size: 20px;
    letter-spacing: 0px;
    text-align: center;
  }

  .home-landing-title span {
    font-size: 15px;
  }

  .home-landing-title a {
    font-size: 15px;
    margin: 20px;
    padding: 6px;
    letter-spacing: 0px;
  }

  .home-mid-section-title {
    font-size: 25px
  }

  .home-mid-section-subtitle {
    font-size: 16px;
  }

  .home-services {
    width: 100%;
    flex-wrap: wrap;
  }

  .iconview {
    margin-bottom: 60px;
  }

  .iconview-btn {
    font-size: 17px;
  }

  .home-mid-bottom-content {
    width: 100%;
  }

  .home-mid-bottom-title {
    font-size: 16px
  }

  .detail-demo {
    text-align: center;
  }

  .detail-demo-label {
    font-size: 15px
  }

  .detail-demo-text {
    font-size: 13px;
  }

  .action-to-call h1 {
    /*width: 60%;*/
    font-size: 30px;
  }

  .action-items div {
    margin: 10px;
  }

  .action-items h3 {
    font-size: 15px;
  }

  .action-items p {
    font-size: 13px;
  }

  .action-to-call a {
    font-size: 20px;
  }

  .publishers-outstream {
    width: 100%;
    text-align: center;
  }

  .pubs-outstream-content {
    padding: 15px;
  }

  .publishers-instream {
    width: 100%;
  }

  .pubs-outstream-image {
    justify-content: center;
  }

  .pubs-instream-content {
    padding: 15px;
    text-align: center;
  }

  .pubs-instream-image div {
    width: 100%;
  }

  .pubs-instream-image img {
    max-width: 100%;
  }

  .publishers-action-image {
    width: 100%
  }

  .publishers-action-image img {
    max-width: 100%;
  }

  .publishers-action h2 {
    font-size: 17px;
    text-align: center;
  }

  .advertisers-top-placeholder {
    width: 0px;
  }

  .advertisers-top-content {
    padding: 15px;
    text-align: center;
  }

  .advertisers-top-content h1 {
    width: 100%;
    font-size: 
  }

  .advertisers-top-content p {
    font-size: 16px;
  }

  .advertisers-middle {
    width: 100%
  }

  .advertisers-middle-left {
    width: 100%;
    padding: 15px;
    text-align: center;
  }

  .email-form {
    width: 100%;
  }

  .footer {
    font-size: 13px;
  }

  .pp-tc {
    width: 100%;
    padding: 30px 0;
  }

  .baseline-demo {
    width: 100%;
  }
}
